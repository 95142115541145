import React from 'react';
import './MainContent.css';

const MainContent = () => {
  return (
    <div className="main-content">
      <div className="emoji-background">
        {[...Array(150)].map((_, index) => (
          <span key={index} className="emoji">🦏</span>
        ))}
      </div>
      <img src={`${process.env.PUBLIC_URL}/image.jpg`} alt="SUDAN Picture" className="square-picture" />

      <p>Supply: 1B</p>
      <p>Liquidity Burned ( pump.fun )</p>
      <p>Tax 0%</p>
      <p>CA: 46kgQwHjWnDCR4MDwaytKRvQxp4R7vVw5Ca5Z2uWpump</p>      
      <p className="miocat-description">
      Sudan was the last male northern white rhinoceros, symbolizing the critical state of rhino conservation. Born in 1973, he lived most of his life in the wild before being moved to the Ol Pejeta Conservancy in Kenya for protection. Despite conservation efforts, Sudan died in 2018 at the age of 45, leaving only two female northern white rhinos behind. His passing highlighted the urgent need to protect endangered species and preserve biodiversity. Sudan's legacy continues through scientific efforts to save his subspecies through advanced reproductive techniques.
      </p>
      <div className="marquee-container">
        <hr />
        <marquee>$SUDAN $SUDAN $SUDAN $SUDAN $SUDAN $SUDAN $SUDAN $SUDAN $SUDAN $SUDAN $SUDAN $SUDAN $SUDAN $SUDAN $SUDAN $SUDAN $SUDAN $SUDAN $SUDAN $SUDAN $SUDAN $SUDAN $SUDAN $SUDAN $SUDAN $SUDAN $SUDAN $SUDAN $SUDAN $SUDAN $SUDAN $SUDAN</marquee>
      </div>
    </div>
  );
};

export default MainContent;
